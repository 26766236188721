<template>
  <div>
    <b-row class="mb-4 p-10">
      <b-col class="text-center">
        <h2 class="mb-6 header-text thank-message" align="center" v-bind:style="$store.getters.writingColourBlockText">{{$store.getters.thankYouMessageText}}</h2>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "application-success"
}
</script>

<style>
.thank-message {
  white-space: pre-line
}
</style>