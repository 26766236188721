<template>
  <div class="mt-3">
    <b-card>
      <h3 class="midblue-text" v-bind:style="$store.getters.elementColourBlockText">Your direct debit details</h3>
      <b-row class="p-6 payment-detail-container" v-bind:style="$store.getters.optionBox">
        <b-col cols="12">
          <b-form-group label="Full name of Account Holder">
            <b-form-input
              v-model="accountName"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Account Number">
            <b-form-input
              v-model="accountNumber"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Sort code">
            <b-form-input
              v-model="sortCode"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
          <div class="mb-4">Please note that the Direct Debit collection date will be the 1st of each month</div>
        </b-col>
        <b-col cols="12">
          <ul class="payment-text">
            <li>This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits.</li>
            <li>If there are any changes to the amount, date or frequency of your Direct Debit the insurer will notify you at least ten working days in advance of your account being debited or as otherwise agreed. 
              If you request the Insurer to collect a payment, confirmation of the amount and date will be given to you at the time of the request.</li>
            <li>If an error is made in the payment of your Direct Debit by the Insurer or your bank or building society you are entitled to a full and immediate refund of the amount paid from you bank or building society.</li>
            <li>If you receive a refund you are not entitled to, you must pay it back when the insurer asks you to.</li>
            <li>You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify the Insurer.</li>
          </ul>
        </b-col >
        <b-col align="center" cols="12">
          <b-button class="large-button" variant="primary" @click="buyPolicy" :disabled="!canProceed || !isInformationCorrect" v-bind:style="$store.getters.primaryButtonBox">
            <span v-if="isBuyLoading"><b-spinner large></b-spinner></span>
            <span v-if="!isBuyLoading">Buy policy</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "payment-details",
  data() {
    return {
      isBuyLoading: false
    }
  },
  methods: {
    buyPolicy: function(){
      console.log("APPLICATION CONDUCTED");
      this.isBuyLoading = true;
      this.$store.dispatch("applyProtectionProduct").then(() => {
        this.isBuyLoading = false;
        this.$store.commit("updateProtectionPaymentStep", "success");
        
        var applicationData = this.quoteApplication;
        // applicationData.selectedProduct = this.product;

        var pluginMessage = { 
          messageType: "application",
          payload: {
            clientDetails: this.quoteApplicationDetails,
            applicationDetails: applicationData
          }
        };

        // Transmit to parent window.
        window.parent.postMessage(JSON.stringify(pluginMessage),"*");
      });
    }
  },
  mounted(){
    console.log(this.$store.state.protection.quoteApplication);
  },
  computed: {
    accountName: {
      get() {
        return this.quoteApplication.accountName;
      },
      set(value) {
        let quoteApplication = { ...this.quoteApplication };
        quoteApplication.accountName = value;
        this.$store.commit("updateProtectionQuoteApplication", quoteApplication);
      }
    },
    accountNumber: {
      get() {
        return this.quoteApplication.accountNumber;
      },
      set(value) {
        let quoteApplication = { ...this.quoteApplication };
        quoteApplication.accountNumber = value;
        this.$store.commit("updateProtectionQuoteApplication", quoteApplication);
      }
    },
    sortCode: {
      get() {
        return this.quoteApplication.sortCode;
      },
      set(value) {
        let quoteApplication = { ...this.quoteApplication };
        quoteApplication.sortCode = value;
        this.$store.commit("updateProtectionQuoteApplication", quoteApplication);
      }
    },
    quoteApplication: {
      get(){
        return this.$store.state.protection.quoteApplication;
      }
    },
    quoteApplicationDetails: {
      get(){
        return this.$store.state.protection.applicationDetails;
      }
    },
    isInformationCorrect: {
      get() {
        return this.quoteApplication.isInformationCorrect;
      }
    },
    canProceed: {
      get(){
        return this.accountName && this.accountNumber && this.sortCode.trim();
      }
    },
    product: {
      get(){
        return this.$store.state.protection.selectedProduct;
      }
    }
  }
}
</script>

<style lang="scss">
.payment-detail-container {
  background-color: #ACACAC;
}

.payment-text {
  font-size: 11px;
}

.payment-text {
  li {
    margin-bottom: 10px;
  }
}
</style>